import {Article} from './articles.model';

export interface ArticlesFilter {
  // Pagination
  pageSize?: number;
  selectedPage?: number;

  // Sorting
  sortBy?: string;
  descending?: boolean;

  // Population
  populate?: ('subjectAreas' | 'topics' | 'authorId')[];
  select?: (keyof Article)[];

  // Filter (New and safely in usage)
  q?: string; // Search string

  institution?: string;
  topics?: string[]; // Kategorien
  subjectAreas?: string[]; // Fachrichtungen
  federalStates?: string[]; // Bundesländer

  only?: string; // Filter for only specific articles

  onlyForInstitution?: string; // Unlike institution, this also includes articles that are not restricted to any institution
  onlyForFederalState?: string;

  curAuthor?: string;

  // For Home page -> registerDate must be in the future
  hasOpenRegistration?: boolean;

  // For Article Creation -> check whether an article has the same links
  links?: string[];
}

export function parseArticleQuery(filter: ArticlesFilter, params: any) {
  const {
    pageSize,
    selectedPage,
    sortBy,
    descending,
    q,
    topics,
    subjectAreas,
    federalStates,
    only,
  } = params;
  filter.pageSize = +pageSize || 10;
  filter.selectedPage = +selectedPage || 1;
  filter.sortBy = sortBy || 'updatedAt';
  filter.descending = descending && descending !== 'false';
  filter.q = q;
  filter.topics = topics?.split(',');
  filter.subjectAreas = subjectAreas?.split(',');
  filter.federalStates = federalStates?.split(',');
  filter.only = only;
}

export function writeArticleQuery(filter: ArticlesFilter): Record<string, string | string[] | number | boolean | undefined> {
  const {pageSize, selectedPage, sortBy, descending, q, topics, subjectAreas, federalStates, only} = filter;

  return {
    pageSize,
    selectedPage,
    sortBy,
    descending,
    q,
    topics: topics?.join(','),
    subjectAreas: subjectAreas?.join(','),
    federalStates: federalStates?.join(','),
    only,
  };

}
